import { asset_cdn } from '../helpers/helpers';

const WALLET_CONNECT_SUCCESS = asset_cdn('assets/missions/wallet-connect-success.svg');
const CDN_CHARTS_LOADER = asset_cdn('crypto-prices/assets/chart-loader.svg');
const NFT_CERTIFICATE_UNREVEALED = asset_cdn('assets/missions/nft-certificate-unrevealed.jpg');

export {
    WALLET_CONNECT_SUCCESS,
    CDN_CHARTS_LOADER,
    NFT_CERTIFICATE_UNREVEALED,
};
